import React, { useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import SessionStorageManager from '../../cuploo-src/utils/manageSessionStorage';
import { constructUrl } from '../utils'
const MainQuestionsContext = React.createContext();
MainQuestionsContext.displayName = 'MainQuestionsContext';
export function MainQuestionsProvider(props) {
    const [state, setState] = useState(SessionStorageManager.readSessionStorage(MainQuestionsContext.displayName, {
        mainQuestions: [],
        selectedMainQuestionId: [],
        wasMainQuestionsLoaded: false,
        hasCreationButton:true,
        resourceName:'Pregunta Principal'
    }));
    const sessionStateMiddleware = (values) => {
        SessionStorageManager.writeSessionStorage(MainQuestionsContext.displayName, values);
        setState(values);
    }
    const getMainQuestions = async () => {
        let response = await Axios.get(constructUrl(`api/main_questions`));
        let mainQuestions = [...response.data.items];
        sessionStateMiddleware({ ...state, mainQuestions, wasMainQuestionsLoaded: true });
    }
    useEffect(() => {
        getMainQuestions();
    }, [])

    const value = useMemo(() => {
        return ([
            state,
            sessionStateMiddleware,
            getMainQuestions,
        ])
    }, [state])

    return <MainQuestionsContext.Provider value={value} {...props} />
}

export function useMainQuestions() {
    const context = React.useContext(MainQuestionsContext);
    if (!context) {
        throw new Error('useMainQuestions debe estar dentro del proveedor MainQuestionsProvider');
    }
    return context;
}
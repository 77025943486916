/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Brand } from "../brand/Brand";
import { KTUtil } from "./../../../_assets/js/components/util";
import { AsideItem } from "./includes/AsideItem";
import { useHistory } from "react-router-dom";

export function Aside() {
  const history = useHistory();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);

  const tabs = {
    tabId1: "kt_aside_tab_1",
    tabId2: "kt_aside_tab_2",
    tabId3: "kt_aside_tab_3",
    tabId4: "kt_aside_tab_4",
    tabId5: "kt_aside_tab_5",
    tabId6: "kt_aside_tab_6",
  };
  const [activeTab, setActiveTab] = useState(tabs.tabId1);
  const handleTabChange = (id) => {
    setActiveTab(id);
    const asideWorkspace = KTUtil.find(
      document.getElementById("kt_aside"),
      ".aside-secondary .aside-workspace"
    );
    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace);
    }
  };

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
      >
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
            {/* begin::Nav */}
            <ul className="list-unstyled flex-column" role="tablist">
              <AsideItem classNameIcon='fas fa-users' title='Usuarios' tabId={tabs.tabId1} activeTab={activeTab} tabs={tabs} handleTabChange={handleTabChange}
                onClick={() => {
                  history.push('/users');
                }
                } />
              <AsideItem classNameIcon='fas fa-cubes' title='Speed Packs' tabId={tabs.tabId2} activeTab={activeTab} tabs={tabs} handleTabChange={handleTabChange}
                onClick={() => {
                  history.push('/speed_packs');
                }
                }
              />
              <AsideItem classNameIcon='fas fa-user-times' title='Usuarios reportados' tabId={tabs.tabId3} activeTab={activeTab} tabs={tabs} handleTabChange={handleTabChange}
                onClick={() => {
                  history.push('/user_reports');
                }}
              />
              <AsideItem classNameIcon='fas fa-cube' title='Live Speed Dates' tabId={tabs.tabId4} activeTab={activeTab} tabs={tabs} handleTabChange={handleTabChange}
                onClick={() => {
                  history.push('/speed_dates')
                }}
              />
              {/* <AsideItem classNameIcon='fas fa-robot' title='Simulador LSD' tabId={tabs.tabId5} activeTab={activeTab} tabs={tabs} handleTabChange={handleTabChange}
                onClick={()=> window.open("http://appcereza.com/lsdsimulator/", "_blank")}
              /> */}
              <AsideItem classNameIcon='fas fa-sign-out-alt' title='Cerrar Sesión' tabId={tabs.tabId6} activeTab={activeTab} tabs={tabs} handleTabChange={handleTabChange}
                onClick={() => {
                  history.push("/logout");
                }} />
            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}
        </div>
        {/* end::Primary */}

      </div>
      {/* end::Aside */}
    </>
  );
}

import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { SpeedPacksProvider } from '../../context/SpeedPacksContext';
import CreateSpeedPack from './CreateSpeedPack';
import { SpeedPacksList } from './SpeedPacksList';
import UpdateSpeedPack from './UpdateSpeedPack';

export default (props) => {
    let match = useRouteMatch();
    return (
        <SpeedPacksProvider>
            <Switch>
                <Route path={`${match.path}/editar`} component={UpdateSpeedPack} />
                <Route path={`${match.path}/nuevo`} component={CreateSpeedPack} />
                <Route path={`${match.path}`} component={SpeedPacksList} />
            </Switch>
        </SpeedPacksProvider>
    );
}


import React from 'react';
import AppForm from '../../../includes/partials/forms/AppForm';

export default function MainQuestionForm({ mainQuestion, onSubmit }) {
    let formData = [
        {
            id: "video_url",
            label: "URL del video",
            placeholder: "Ingrese la url del video",
            type: "text",
            validationType: "string",
            value: mainQuestion ? mainQuestion["video_url"] : "",
            validations: [
                {
                    type: "required",
                    params: ["La url del video es requerida"]
                },
            ]
        }
    ];

    return (
        <>
            <AppForm items={formData} onSubmit={onSubmit} isEditForm={mainQuestion != null} submitLabel={mainQuestion ? 'Actualizar' : "Crear"} />
        </>
    )
}


import React from 'react'
import { AppContainer } from '../Layout/AppContainer'
import { constructUrl, findById } from '../../utils';
import Alert from '../../includes/Alert';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import MainQuestionForm from './forms/MainQuestionForm';
import { useMainQuestions } from '../../context/MainQuestionsContext';
export default function UpdateMainQuestion() {
    const [mainQuestionsState,setMainQuestionsState,getMainQuestions]=useMainQuestions();
    const history = useHistory();
    const currentMainQuestion=findById(mainQuestionsState.mainQuestions,mainQuestionsState.selectedMainQuestionId);
    const onSubmit = async (values, actions) => {
        try {
            let response = await Axios.post(constructUrl(`api/main_questions/${currentMainQuestion.id}/edit`), values);
            if (!response.data.ok) throw new Error('Error al actualizar');
            Alert({ icon: 'success', text: response.data.ok });
            getMainQuestions();
            history.goBack();
        } catch (error) {
            Alert({ icon: 'error', title: 'Error', text: error });
        } finally {
            actions.setSubmitting(false);
        }
    }
    return (
        <AppContainer title="Actualización de Pregunta Principal">
            <MainQuestionForm onSubmit={onSubmit} mainQuestion={currentMainQuestion}/>
        </AppContainer>
    )
}


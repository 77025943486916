import React, { useEffect, useState } from 'react'
import SessionStorageManager from '../../cuploo-src/utils/manageSessionStorage';
import app from '../firebaseConfig';
import { useHistory } from 'react-router-dom';
const AuthContext = React.createContext();
AuthContext.displayName = 'AuthContext';
export const AuthProvider = (props) => {
    const history = useHistory();
    const [state, setState] = useState(SessionStorageManager.readSessionStorage(AuthContext.displayName, {
        user: null
    }));
    useEffect(() => {
        app.auth().onAuthStateChanged(function (user) {
            if (user) {
                sessionStateMiddleware({ ...state, user: user });
                history.push("/");
            }
            if (!user) {
                sessionStateMiddleware({
                    user: null,
                })
                history.push("/auth/login");
            }
        });
    }, [])
    const sessionStateMiddleware = (values) => {
        SessionStorageManager.writeSessionStorage(AuthContext.displayName, values);
        setState(values);
    }

    const value = React.useMemo(() => {
        return ([
            state,
            sessionStateMiddleware])
    }, [state]);

    return (
        <AuthContext.Provider value={value} {...props} />
    )
}

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    return context;
}
import React from 'react';
import { Form } from 'react-bootstrap';

function AppField({ item, formik }) {
    const renderInput = () => {
        switch (item.type) {
            case 'array':
                return (
                    <select name={item.id} defaultValue={formik ? formik.values[item.id] : item.value} onChange={(evt) => {
                        formik && formik.handleChange(evt);
                        item.handleChange && item.handleChange(evt)
                    }}>
                        {!item.withoutPlaceHolder &&
                            <option value=''></option>
                        }
                        {item.values.map((value) => {
                            return (
                                <option
                                    key={'option_' + value.id}
                                    value={value.id}
                                >
                                    {value.value}
                                </option>
                            )
                        })}
                    </select>);
            case 'boolean':
                return (
                    <div></div>
                    // <Checkbox
                    //     name={item.id}
                    //     value={formik ? formik.values[item.id] : item.value}
                    //     onChange={(e, data) => {
                    //         e.target.name = data.name;
                    //         e.target.value = data.checked;
                    //         formik && formik.handleChange(e)
                    //         item.handleChange && item.handleChange(e)
                    //     }}
                    //     checked={formik.values[item.id]} />
                );
            default:
                return (
                    <Form.Control
                        name={item.id}
                        type={item.type || 'text'}
                        onChange={(evt) => {
                            formik && formik.handleChange(evt)
                            item.onChange && item.onChange(evt, formik)
                            item.handleChange && item.handleChange(evt);
                        }}
                        value={formik ? formik.values[item.id] : item.value}
                    />
                );
        }
    }

    if (!item.hidden) {
        return (
            <Form.Group width={16} style={{ position: 'relative' }}>
                {/* {item.hint &&
                    <Label id={`hint_${item.id}`} style={{ position: 'absolute', right: 0, top: 30, visibility: 'hidden', zIndex: 1000 }} pointing>{item.hint}</Label>
                } */}
                <Form.Label>{item.label}
                    {/* {item.hint &&
                        <Icon onClick={() => {
                            let visibility = { visible: 'hidden', hidden: 'visible' }
                            let element = document.getElementById(`hint_${item.id}`);
                            element.style.visibility = visibility[element.style.visibility];
                        }} style={{ cursor: 'pointer' }}
                            name='question circle outline' />
                    } */}
                </Form.Label>
                {renderInput()}
                {/* {
                    item.tag &&
                    <Label as='a' tag style={{ position: 'absolute', right: 12, top: 12 }}>
                        {item.tag}
                    </Label>
                } */}
                {formik && formik.errors[item.id] && formik.touched[item.id] &&
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors[item.id]}</div>
                    </div>
                }
            </Form.Group>
        );
    } else {
        return null;
    }
}

export default AppField;
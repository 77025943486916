import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { UsersProvider } from '../../context/UsersContext';
import { UserSpeedPacks } from './speed_packs';
import { UsersList } from './UsersList';

export default (props) => {
    let match = useRouteMatch();
    return (
        <UsersProvider>
            <Switch>
                <Route path={`${match.path}/speed-packs`} component={UserSpeedPacks} />
                <Route path={`${match.path}`} component={UsersList} />
            </Switch>
        </UsersProvider>
    );
}


/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { CsvBuilder } from "filefy";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { getValueBySelector } from "../../../utils";
import MyTable from "../pdf/TablePdf";


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    alignItems: "center",
  },
}));

export function ExportDropdown({
  dataExport = [],
  headerExport = [],
  title = "archivo",
  tablePdf,
  csvData2Export = [],
  csvHeaders2Export = [],
}) {
  const classes = useStyles();
  const headers = [];
  const itemsBody = [];
  const bodyList = [];
  const options = {
    orientation: "landscape",
    unit: "in",
  };
  const dataInfoItem = dataExport;

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: dataExport.length,
  });
  const emptyRows =
    state.rowsPerPage -
    Math.min(
      state.rowsPerPage,
      dataInfoItem.length - state.page * state.rowsPerPage
    );

  const arrayHeader = () => {
    (csvHeaders2Export.length > 0 ? csvHeaders2Export : headerExport).map((headerAndSelector) => {
      headers.push(headerAndSelector.header);
      itemsBody.push(headerAndSelector);
    });
  };

  const arrayItems = () => {
    (csvData2Export.length > 0 ? csvData2Export : dataExport).map((item) => {
      let listString = [];
      itemsBody.map((headerAndSelector) => {
        let value = getValueBySelector(item, headerAndSelector);
        if (value) {
          listString.push(value);
        } else {
          listString.push("-");
        }
      });
      bodyList.push(listString);
    });
  };

  useEffect(() => {
    arrayHeader();
    arrayItems();
  }, []);
  const exportCSV = () => {
    const csvBuilder = new CsvBuilder(`${title}.csv`)
      .setColumns(headers)
      .addRows(bodyList)
      .exportFile();
  };


  return (
    <>
      {/*begin::Navigation*/}
      <ul className="navi navi-hover py-5">
        <li className="navi-item">
          <a onClick={exportCSV} href="#" className="navi-link">
            <span className="navi-icon">
              <i className="flaticon2-file"></i>
            </span>
            <span className="navi-text">CSV</span>
          </a>
        </li>
        <li className="navi-item">
          <div>
            <PDFDownloadLink className="navi-link" document={<MyTable data={dataExport} headersAndSelectors={headerExport} />} fileName={`${title}.pdf`}>
              {/* {({ loading }) =>
                loading ? 'downloading...' : 'PDF'
              } */}
              <span className="navi-icon">
                <i className="flaticon2-list-3"></i>
              </span>
              <span className="navi-text">PDF</span>
            </PDFDownloadLink>
          </div>
        </li>
      </ul>
      {/*end::Navigation*/}
    </>
  );
}

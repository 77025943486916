import React, { useRef, useState } from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useUsers } from "../../../context/UsersContext";
import AppTable from "../../../includes/partials/tables/AppTable";
import { AppContainer } from "../../Layout/AppContainer";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import Axios from "axios";
import { constructUrl } from "../../../utils";
export const LiveSpeedDateUsers = () => {
    let { id } = useParams();
    const tablePdf = useRef(null);
    const history = useHistory();
    const match = useRouteMatch();
    const [usersState, setUsersState] = useUsers();
    const [idsOfUsersInCurrentLSD, setIdsOfUsersInCurrentLSD] = useState([])
    let users = usersState.users;
    if (!usersState.wasUsersLoaded) {
        return (
            <div className="d-flex justify-content-center h-100 align-items-center">
                <Spinner animation="grow" />
            </div>
        );
    }
    let headersAndSelectors = [
        { header: "Nombre", selector: "name" },
        { header: "CC", selector: "nip" },
        { header: "Género", selector: "gender.gender" },
        { header: "Orientación Sexual", selector: "sexualOrientation.sexual_orientation" },
    ];
    const buttonsOfOptions = (id) => {
        const buttons = [];
        buttons.push(
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="latest-project">Agregar</Tooltip>}
            >
                <Button
                    className={`btn btn-${idsOfUsersInCurrentLSD.includes(id) ? 'success' : 'primary'} btn-sm`}
                    onClick={() => {
                        if (idsOfUsersInCurrentLSD.includes(id)) {
                            setIdsOfUsersInCurrentLSD(idsOfUsersInCurrentLSD.filter(candidate => candidate !== id));
                        } else {
                            setIdsOfUsersInCurrentLSD([...idsOfUsersInCurrentLSD, id]);
                        }
                    }}
                >
                    {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                    <i className={`far fa-${idsOfUsersInCurrentLSD.includes(id) ? 'minus-square' : 'plus-square'}`} />
                </Button>
            </OverlayTrigger>
        );
        return buttons;
    };
    return (
        <AppContainer
            title={"Usuarios del live speed date " + id}
            hasExportationButton={true}
            dataExport={users}
            headerExport={headersAndSelectors}
            tablePdf={tablePdf}
        >
            <AppTable
                data={users}
                headersAndSelectors={headersAndSelectors}
                search
                buttonsOfOptions={buttonsOfOptions}
                tablePdf={tablePdf}
            />
            <Button
                className={`btn btn-primary btn-sm`}
                onClick={() => {
                    idsOfUsersInCurrentLSD.forEach(async (userId) => {
                        await Axios.get(constructUrl(`api/speed_dates/${id}/attendance/${userId}`));
                    });
                    alert('clusters predefinidos');
                    history.go(-2);
                }}
            >
                Enviar Información
            </Button>
        </AppContainer>
    );
}
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import React, { useRef } from "react";
import { useUsers } from "../../context/UsersContext";
import AppTable from "../../includes/partials/tables/AppTable";
import { AppContainer } from "../Layout/AppContainer";
import { useHistory, useRouteMatch } from "react-router-dom";
import { hashtag } from "../../utils";
const EXTRASELECTORSCSV = [
  { header: 'Estatura', selector: "stature" },
  { header: 'Estatura (propecto)', selector: "prospectus_stature" },
  { header: 'Complexión', selector: "complexion" },
  { header: 'Complexión (prospecto)', selector: "prospectus_complexion" },
  { header: 'Alcohol', selector: "alcohol" },
  { header: 'Alcohol (prospecto)', selector: "prospectus_alcohol" },
  { header: 'Tabaco', selector: "tabaco" },
  { header: 'Tabaco (prospecto)', selector: "prospectus_tabaco" },
  { header: '¿Tiene hijos?', selector: "have_children" },
  { header: '¿Tiene hijos? (prospecto)', selector: "prospectus_have_children" },
  { header: '¿Quiere hijos?', selector: "want_children" },
  { header: '¿Quiere hijos? (prospecto)', selector: "prospectus_want_children" },
  { header: 'Religión', selector: "religion" },
  { header: 'Religión (prospecto)', selector: "prospectus_religion" },
  { header: 'Idiomas', selector: "idioms" },
  { header: 'Idiomas (prospecto)', selector: "prospectus_idioms" },
  { header: 'Edad (prospecto)', selector: "prospectus_age" },
]
export const UsersList = (props) => {
  const history = useHistory();
  const match = useRouteMatch();

  const tablePdf = useRef(null);

  const [usersState, setUsersState] = useUsers();
  let users = usersState.users;
  if (!usersState.wasUsersLoaded) {
    return (
      <div className="d-flex justify-content-center h-100 align-items-center">
        <Spinner animation="grow" />
      </div>
    );
  }
  let headersAndSelectors = [
    { header: "Nombre", selector: "name" },
    { header: "Apellido", selector: "last_name" },
    { header: "CC", selector: "nip" },
    { header: "Género", selector: "gender.gender" },
    { header: "Correo", selector: "email" },
    { header: "Teléfono", selector: "phone" },
  ];
  let csvHeadersAndSelectors = [
    ...headersAndSelectors,
    {
      header: "Intereses", selector: "interests", skin: hashtag,
      where: { is_prospect_pleasure: false }
    },
    {
      header: "Intereses (prospecto)", selector: "interests", skin: hashtag
      , where: { is_prospect_pleasure: true }
    },
    ...EXTRASELECTORSCSV
  ];
  const buttonsOfOptions = (id) => {
    const buttons = [];
    buttons.push(
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="latest-project">Speed Packs Comprados</Tooltip>}
      >
        <Button
          className="btn btn-primary btn-sm"
          onClick={() => {
            setUsersState({ ...usersState, selectedUserId: id });
            history.push(`${match.path}/speed-packs`);
          }}
        >
          <i className="fas fa-cubes" />
        </Button>
      </OverlayTrigger>
    );
    return buttons;
  };
  return (
    <AppContainer
      title="Usuarios"
      hasExportationButton={true}
      dataExport={users}
      headerExport={headersAndSelectors}
      csvData2Export={users}
      csvHeaders2Export={csvHeadersAndSelectors}
      tablePdf={tablePdf}
    >
      <AppTable
        data={users}
        headersAndSelectors={headersAndSelectors}
        search
        buttonsOfOptions={buttonsOfOptions}
        tablePdf={tablePdf}
      />
    </AppContainer>
  );
};

import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { createYupSchema, getDifferentValues } from '../../../utils';
import AppField from './fields/AppField';
import Alert from '../../Alert';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
const getInitialValues = (items) => {
    let initialValues = {};
    items.forEach(item => {
        initialValues[item.id] = item.value || '';
    });
    return initialValues;
}
function AppForm({ items, onSubmit, submitLabel, isEditForm, isViewForm }) {
    let yepSchema = items.reduce(createYupSchema, {});
    let initialValues = getInitialValues(items);
    let formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape(yepSchema),
        onSubmit: (values, actions) => {
            if (isEditForm) {
                values = getDifferentValues(initialValues, values);
                if (Object.keys(values).length === 0) {
                    Alert({ icon: 'error', title: 'Error', text: 'No hubo cambios en la información' });
                    actions.setSubmitting(false);
                    return;
                }
            }
            onSubmit(values, actions)
        }
    });
    // useEffect(() => {
    //     console.log('reseteo formulario')
    //     formik.resetForm();
    // }, [items])
    return (
        <div>
            <Form>
                {items.map(item => {
                    return <AppField item={item} formik={formik} />
                })}
                {!isViewForm && <Button style={{minWidth:86}}type='submit' onClick={formik.handleSubmit}
                    children={formik.isSubmitting ? <span className="ml-3 spinner spinner-white"></span> : submitLabel || 'Crear'} disabled={formik.isSubmitting}
                />

                }
            </Form>
        </div>
    );
}

export default AppForm;
import React from 'react'
import { AppContainer } from '../Layout/AppContainer'
import { constructUrl, findById } from '../../utils';
import Alert from '../../includes/Alert';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useSpeedDates } from '../../context/SpeedDatesContext';
import SpeedDateForm from './forms/SpeedDateForm';
export default function UpdateSpeedDate() {
    const [speedDatesState,setSpeedDatesState,getSpeedDates]=useSpeedDates();
    const history = useHistory();
    const currentSpeedDate=findById(speedDatesState.speedDates,speedDatesState.selectedSpeedDateId);
    const onSubmit = async (values, actions) => {
        try {
            let response = await Axios.post(constructUrl(`api/speed_dates/${currentSpeedDate.id}/edit`), values);
            if (!response.data.ok) throw new Error('Error al actualizar');
            Alert({ icon: 'success', text: response.data.ok });
            getSpeedDates();
            history.goBack();
        } catch (error) {
            Alert({ icon: 'error', title: 'Error', text: error });
        } finally {
            actions.setSubmitting(false);
        }
    }
    return (
        <AppContainer title="Actualización de Live Speed Date">
            <SpeedDateForm onSubmit={onSubmit} speedDate={currentSpeedDate}/>
        </AppContainer>
    )
}


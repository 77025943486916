import { Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useUsers } from "../../../context/UsersContext";
import AppTable from "../../../includes/partials/tables/AppTable";
import { AppContainer } from "../../Layout/AppContainer";

import Alert from "../../../includes/Alert";
import Axios from "axios";
import { findById, getNaturalizedDate, constructUrl } from "../../../utils";
export const SpeedDateInfo = ({ id_item }) => {
  const [loading, setLoading] = useState(false);

  const [speedDateInfo, setspeedDateInfo] = useState([
    {
      date: "skskkd",
      attendance: "3",
      match_quantities: "3",
      report_quantities: "4",
    },
  ]);
  const getItemLiveSpeedDate = async () => {
    setLoading(true);
    let response = await Axios.get(constructUrl(`api/speed_dates/${id_item}`));
    let reports = [{ ...response.data }];
    setspeedDateInfo(reports);

    setLoading(false);
  };
  useEffect(() => {
    getItemLiveSpeedDate();
  }, [])
  if (loading) {
    return (
      <div className="d-flex justify-content-center h-100 align-items-center">
        <Spinner animation="grow" />
      </div>
    );
  }

  let headersAndSelectors = [
    {
      header: "Date",
      selector: "date",
      skin: getNaturalizedDate,
      params: ["de", false, true],
    },
    { header: "Cantidad de Asistentes", selector: "attendance" },
    { header: "Cantidad de Match", selector: "match_quantities" },
    {
      header: "Cantidad de Reportes",
      selector: "report_quantities",
    },
  ];

  return (
    <AppContainer title="SpeedDate Info" hasExportationButton={false}>
      <AppTable
        data={speedDateInfo}
        headersAndSelectors={headersAndSelectors}
        buttonsOfOptions={null}
      />
    </AppContainer>
  );
};

import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import { Table, TableHeader, TableBody, TableCell, DataTableCell } from '@david.kucsai/react-pdf-table';
import { getValueBySelector } from '../../../utils';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-beetwen',
        alignItems: 'center'
    },
    subHeader: {
        marginVertical: 10
    },
    logo: {
        flex: 1
    },
    title: {
        flex: 3,
        fontSize: 14,
        textAlign: 'center',
        fontStyle: 'bold'
    },
    data: {
        flex: 1,
        fontSize: 10,
        textAlign: 'right'
    },
    tHeader: {
        backgroundColor: '#B10031',
        color: 'white'
    },
});

const MyTable = ({ data, headersAndSelectors }) => {
    const getHeaders = () => {
        const headers = [];
        headersAndSelectors.forEach((headerAndSelector) => {
            headers.push(<TableCell style={styles.tHeader}>
                {headerAndSelector.header}
            </TableCell>);
        })
        return headers;
    }
    const getData = () => {
        const data = [];
        headersAndSelectors.forEach((headerAndSelector) => {
            data.push(
                <DataTableCell
                    getContent={(r) => getValueBySelector(r, headerAndSelector)}
                />);
        })
        return data;
    }
    return (
        <Document>
            <Page size="TABLOID" orientation="landscape" size="A4" style={styles.page}>
                <Table
                    data={data}
                >
                    <TableHeader textAlign={"center"}>
                        {getHeaders()}
                    </TableHeader>
                    <TableBody textAlign={"center"}>
                        {getData()}
                    </TableBody>
                </Table>
            </Page >
        </Document >
    )
};


export default MyTable;
import axios from "axios";
import app from "../../../firebaseConfig";

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

export function login(email, password) {
  return app.auth()
    .signInWithEmailAndPassword(email, password);
  // return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return app
    .auth()
    .sendPasswordResetEmail(email);
  // return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  let user = app.auth().currentUser
  return { data: user }
  // return axios.get(ME_URL);
}

import React, { useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import SessionStorageManager from '../../cuploo-src/utils/manageSessionStorage';
import { constructUrl } from '../utils'
const UsersContext = React.createContext();
UsersContext.displayName = 'UsersContext';
export function UsersProvider(props) {
    const [state, setState] = useState(SessionStorageManager.readSessionStorage(UsersContext.displayName, {
        users: [],
        selectedUserId: -1,
        wasUsersLoaded: false,
    }));
    const sessionStateMiddleware = (values) => {
        SessionStorageManager.writeSessionStorage(UsersContext.displayName, values);
        setState(values);
    }
    const getUsers = async () => {
        const query = {
            graphs: ["gender","speed_packs","sexualOrientation","interests"],
            include_additional_information:true
        }
        let response = await Axios.get(constructUrl(`api/users`,query));
        let users = [...response.data.items];
        sessionStateMiddleware({ ...state, users, wasUsersLoaded: true });
    }
    useEffect(() => {
        getUsers();
    }, [])

    const value = useMemo(() => {
        return ([
            state,
            sessionStateMiddleware,
            getUsers,
        ])
    }, [state])

    return <UsersContext.Provider value={value} {...props} />
}

export function useUsers() {
    const context = React.useContext(UsersContext);
    if (!context) {
        throw new Error('useUsers debe estar dentro del proveedor UsersProvider')
    }
    return context;
}
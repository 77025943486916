import React from 'react'
import { AppContainer } from '../Layout/AppContainer'
import SpeedPackForm from './forms/SpeedPackForm'
import { useSpeedPacks } from "../../context/SpeedPacksContext";
import { constructUrl } from '../../utils';
import Alert from '../../includes/Alert';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
export default function CreateSpeedPack() {
    const history = useHistory();
    const [getSpeedPacks] = useSpeedPacks().slice(-1);
    const onSubmit = async (values, actions) => {
        try {
            let response = await Axios.post(constructUrl('api/speed_packs'), values);
            if (!response.data.ok) throw new Error('Error al crear');
            Alert({ icon: 'success', text: response.data.ok });
            getSpeedPacks();
            history.goBack();
        } catch (error) {
            Alert({ icon: 'error', title: 'Error', text: error });
        } finally {
            actions.setSubmitting(false);
        }
    }
    return (
        <AppContainer title="Creación Speed Pack">
            <SpeedPackForm onSubmit={onSubmit} />
        </AppContainer>
    )
}


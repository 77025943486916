/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState, useRef } from "react";
// import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Dropdown } from "react-bootstrap";
import Axios from "axios";
// import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";
// import { DropdownMenu2 } from "../../dropdowns";
import { ExportDropdown } from "../../../../app/includes/partials/dropdowns";
import MixedWidgetItem from "./includes/MixedWidgetItem";
import { constructUrl, currencyFormat } from "../../../../app/utils";
import Alert from "../../../../app/includes/Alert";

export function MixedWidget1({ className }) {
  const uiService = useHtmlClassService();
  const [dashboardInfo, setDashboardInfo] = useState({
    current_users: 1000,
    reported_users: 30,
    puchased_speed_packs: 500,
    total_sales: 5000,
    total_LSDs: 20,
    total_matches: 30,
  });

  const pageDashboardRef = useRef(null);

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_1_chart");
    if (!element) {
      return;
    }

    const options = getChartOptions(layoutProps);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  ///====================initial getInfoDashboard====================
  const getInfoUsers = async () => {
    try {
      let response = await Axios.get(constructUrl(`api/dashboard_info`));
      setDashboardInfo(response.data);
    } catch (error) {
      Alert({ icon: "error", title: "Error", text: error });
    }
  };

  useEffect(() => {
    getInfoUsers();
  }, []);


  return (
    <div ref={pageDashboardRef}>
      <div className={`card card-custom bg-gray-100 ${className}`}>
        {/* Header */}
        <div className="card-header border-0 bg-danger py-5">
          <h3 className="card-title font-weight-bolder text-white">
            Estadísticas Cuploo
          </h3>
        
        </div>
        {/* Body */}
        <div className="card-body p-0 position-relative overflow-hidden">
          {/* Chart */}
          <div
            id="kt_mixed_widget_1_chart"
            className="card-rounded-bottom bg-danger"
            style={{ height: "200px" }}
          ></div>

          {/* Stat */}
          <div className="card-spacer mt-n25">
            <div className="row m-0 justify-content-between">
              <MixedWidgetItem
                className="mr-7"
                label1="Usuarios actuales"
                classNameIcon="fas fa-users"
                color="warning"
                label2={dashboardInfo.current_users}
              />
              <MixedWidgetItem
                className="mr-7"
                label1="Usuarios reportados"
                classNameIcon="fas fa-user-times"
                color="primary"
                label2={dashboardInfo.reported_users}
              />
              <MixedWidgetItem
                label1="Speed Packs comprados"
                classNameIcon="fas fa-cubes"
                color="info"
                label2={dashboardInfo.puchased_speed_packs}
              />
            </div>
            <div className="row m-0 justify-content-between">
              <MixedWidgetItem
                className="mr-7"
                label1="Ventas totales"
                classNameIcon="fas fa-dollar-sign"
                color="success"
                label2={currencyFormat(dashboardInfo.total_sales, 'USD')}
              />
              <MixedWidgetItem
                className="mr-7"
                label1="Live Speed Dates"
                classNameIcon="fas fa-cube"
                color="warning"
                label2={dashboardInfo.total_LSDs}
              />
              <MixedWidgetItem
                label1="Matches"
                classNameIcon="fas fa-heart"
                color="danger"
                label2={dashboardInfo.total_matches}
              />
            </div>
          </div>

          {/* Resize */}
          <div className="resize-triggers">
            <div className="expand-trigger">
              <div style={{ width: "411px", height: "461px" }} />
            </div>
            <div className="contract-trigger" />
          </div>
        </div>
      </div>
    </div>
  );
}

function getChartOptions(layoutProps) {
  const strokeColor = "#D13647";

  const options = {
    series: [
      {
        name: "Net Profit",
        data: [30, 45, 32, 70, 40, 40, 40],
      },
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 0,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function (val) {
          return "$" + val + " thousands";
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBaseDanger,
      strokeColor: [strokeColor],
      strokeWidth: 3,
    },
  };
  return options;
}

import React, { useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { Button, TableFooter, TablePagination } from "@material-ui/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { findById, getValueBySelector } from "../../../utils";
import { TablePaginationActions } from "../table-pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
  },
  button: {
    backgroundColor: "black",
  },
  input: {
    display: "none",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.background.default, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: 0,
    marginLeft: 0,
    marginTop: 10,
    marginBottom: 10,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function AppTable({
  data,
  headersAndSelectors,
  buttonsOfOptions,
  item,
  search,
  tablePdf
}) {
  const [searchItems, setSearchItems] = useState(data);
  let dataInfoItem = search ? searchItems : data;
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 5,
  });
  const classes = useStyles();
  const emptyRows =
    state.rowsPerPage -
    Math.min(
      state.rowsPerPage,
      dataInfoItem.length - state.page * state.rowsPerPage
    );
  const handleChangePage = (event, newPage) => {
    setState({ ...state, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      ...state,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleSearch = (e) => {
    const textSearch = e.target.value.toLowerCase();
    const newListItems = [];
    if (textSearch === "") return setSearchItems(data);

    data.map((itemlist) => {
      headersAndSelectors.map(({ selector }) => {
        let value = getValueBySelector(itemlist, { selector });
        if (
          value &&
          (value + '').toLowerCase().includes(textSearch)
          //Acá se evita que se vuelva a poner el registro en los resultados
          && !findById(newListItems, itemlist.id)
        ) {
          newListItems.push(itemlist);
        }
      });
    });
    setSearchItems(newListItems);
  };
  return (
    <Paper className={classes.root}>
      {search && (
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={handleSearch}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
      )}
      <Table ref={tablePdf} className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">Item</TableCell>
            {headersAndSelectors.map((headerAndSelector) => (
              <TableCell key={`header_${Math.random()}`} align="center">
                <div title={headerAndSelector.header}>
                  {headerAndSelector.header}
                </div>
              </TableCell>
            ))}
            {buttonsOfOptions && <TableCell align="center">Opciones</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {(state.rowsPerPage > 0
            ? dataInfoItem.slice(
              state.page * state.rowsPerPage,
              state.page * state.rowsPerPage + state.rowsPerPage
            )
            : dataInfoItem
          ).map((row, rowIdx) => (
            <TableRow key={`row_${Math.random()}`}>
              <TableCell align="center" component="th" scope="row">
                {rowIdx + 1 + state.page * state.rowsPerPage}
              </TableCell>
              {headersAndSelectors.map((headerAndSelector) => (
                <TableCell align="center" key={`value_${Math.random()}`}>
                  {getValueBySelector(row, headerAndSelector)}
                </TableCell>
              ))}
              {buttonsOfOptions ? (
                <TableCell align="center">
                  {buttonsOfOptions(item ? row : row.id)}
                </TableCell>
              ) : null}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={headersAndSelectors.length + 2} />
            </TableRow>
          )}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell colSpan={headersAndSelectors.length + 2} align="center">
              <TablePagination
                labelRowsPerPage="Registros por página"
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={dataInfoItem.length}
                rowsPerPage={state.rowsPerPage}
                page={state.page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}

import React from 'react'
import { AppContainer } from '../Layout/AppContainer'
import SpeedDateForm from './forms/SpeedDateForm'
import { constructUrl, getNaturalizedDate } from '../../utils';
import Alert from '../../includes/Alert';
import Axios from 'axios';
import { useHistory, useRouteMatch, Switch, Route } from 'react-router-dom';
import { useSpeedDates } from '../../context/SpeedDatesContext';
import moment from 'moment';
import { useEffect } from 'react';
import { LiveSpeedDateUsers } from './includes/LiveSpeedDateUsers';
import { UsersProvider } from '../../context/UsersContext';
const setCronJobs = async (id, utc0Date) => {
    const times = [
        {
            number: process.env.NODE_ENV === 'production' ? 3 : 5,
            unit: process.env.NODE_ENV == 'production' ? 'hours' : 'minutes'
        },
        { number: 1, unit: 'minutes' },
        { number: process.env.NODE_ENV === 'production' ? 5 : 2, unit: 'minutes' },
        { number: process.env.NODE_ENV === 'production' ? 10 : 4, unit: 'minutes' },
        { number: process.env.NODE_ENV === 'production' ? 15 : 6, unit: 'minutes' },
        { number: process.env.NODE_ENV === 'production' ? 20 : 8, unit: 'minutes' },
        { number: process.env.NODE_ENV === 'production' ? 25 : 10, unit: 'minutes' },
        { number: process.env.NODE_ENV === 'production' ? 30 : 12, unit: 'minutes' },
    ]
    //attendance Notification date 3 hours before
    const attendanceNotification = moment(utc0Date).subtract(times[0].number, times[0].unit);
    const [notificationMin, notificationHour, notificationDay, notificationMonth] = [
        attendanceNotification.get('minutes'), attendanceNotification.get('h'),
        attendanceNotification.get('D'), attendanceNotification.month() + 1];
    //construct clusters 5 minutes before
    const constructClustersDate = moment(utc0Date).subtract(times[1].number, times[1].unit);
    const [constructClustersMin, constructClustersHour, constructClustersDay, constructClustersMonth] = [
        constructClustersDate.get('minutes'), constructClustersDate.get('h'),
        constructClustersDate.get('D'), constructClustersDate.month() + 1];

    //1° ontime
    const LSDDAte = moment(utc0Date);
    const [LSDMin, LSDHour, LSDDay, LSDMonth] = [LSDDAte.get('minutes'), LSDDAte.get('h'),
    LSDDAte.get('D'), LSDDAte.month() + 1];
    //2° 5 minutes after
    const secondRoundDate = moment(utc0Date).add(times[2].number, times[2].unit);
    const [secondRoundMin, secondRoundHour, secondRoundDay, secondRoundMonth] = [
        secondRoundDate.get('minutes'), secondRoundDate.get('h'),
        secondRoundDate.get('D'), secondRoundDate.month() + 1];
    //3° 10 mins after
    const thirdRoundDate = moment(utc0Date).add(times[3].number, times[3].unit);
    const [thirdRoundMin, thirdRoundHour, thirdRoundDay, thirdRoundMonth] = [
        thirdRoundDate.get('minutes'), thirdRoundDate.get('h'),
        thirdRoundDate.get('D'), thirdRoundDate.month() + 1];
    //4° 15 mins after
    const fourthRoundDate = moment(utc0Date).add(times[4].number, times[4].unit);
    const [fourthRoundMin, fourthRoundHour, fourthRoundDay, fourthRoundMonth] = [
        fourthRoundDate.get('minutes'), fourthRoundDate.get('h'),
        fourthRoundDate.get('D'), fourthRoundDate.month() + 1];
    //5° 20 mins after
    const fifthRoundDate = moment(utc0Date).add(times[5].number, times[5].unit);
    const [fifthRoundMin, fifthRoundHour, fifthRoundDay, fifthRoundMonth] = [
        fifthRoundDate.get('minutes'), fifthRoundDate.get('h'),
        fifthRoundDate.get('D'), fifthRoundDate.month() + 1];
    //6° 25 mins after
    const sixthRoundDate = moment(utc0Date).add(times[6].number, times[6].unit);
    const [sixthRoundMin, sixthRoundHour, sixthRoundDay, sixthRoundMonth] = [
        sixthRoundDate.get('minutes'), sixthRoundDate.get('h'),
        sixthRoundDate.get('D'), sixthRoundDate.month() + 1];

    const finishLSDDate = moment(utc0Date).add(times[7].number, times[7].unit);
    const [finishLSDMin, finishLSDHour, finishLSDDay, finishLSDMonth] = [
        finishLSDDate.get('minutes'), finishLSDDate.get('h'),
        finishLSDDate.get('D'), finishLSDDate.month() + 1];
    let payload = {
        "cronTasks": [
            `${notificationMin} ${notificationHour} ${notificationDay} ${notificationMonth} * curl  https://websocket.cuploo.com/live_speed_dates/${id}/notify_attendance`,
            `${constructClustersMin} ${constructClustersHour} ${constructClustersDay} ${constructClustersMonth} * curl  https://cuploo-back-cuploo.vercel.app/api/speed_dates/${id}/construct_clusters`,
            `${LSDMin} ${LSDHour} ${LSDDay} ${LSDMonth} * curl https://websocket.cuploo.com/live_speed_dates/${id}/start`,
            `${secondRoundMin} ${secondRoundHour} ${secondRoundDay} ${secondRoundMonth} * curl https://websocket.cuploo.com/live_speed_dates/${id}/next_round`,
            `${thirdRoundMin} ${thirdRoundHour} ${thirdRoundDay} ${thirdRoundMonth} * curl https://websocket.cuploo.com/live_speed_dates/${id}/next_round`,
            `${fourthRoundMin} ${fourthRoundHour} ${fourthRoundDay} ${fourthRoundMonth} * curl https://websocket.cuploo.com/live_speed_dates/${id}/next_round`,
            `${fifthRoundMin} ${fifthRoundHour} ${fifthRoundDay} ${fifthRoundMonth} * curl https://websocket.cuploo.com/live_speed_dates/${id}/next_round`,
            `${sixthRoundMin} ${sixthRoundHour} ${sixthRoundDay} ${sixthRoundMonth} * curl https://websocket.cuploo.com/live_speed_dates/${id}/next_round`,
            `${finishLSDMin} ${finishLSDHour} ${finishLSDDay} ${finishLSDMonth} * curl https://websocket.cuploo.com/live_speed_dates/${id}/finish`,
        ]
    }
    return await Axios.post('https://websocket.cuploo.com/create_cron_job', payload);

}
function CreateSpeedDateBase() {
    const history = useHistory();
    const match = useRouteMatch();
    const [getSpeedDates] = useSpeedDates().slice(-1);
    useEffect(() => {
        // history.push('../');
    }, []);
    const onSubmit = async (values, actions) => {
        try {
            let response = await Axios.post(constructUrl('api/speed_dates'), values);
            if (!response.data.ok) throw new Error('Error al crear');
            const newLSDId = response.data.newItem.id;
            response = await setCronJobs(response.data.newItem.id, moment(values.date).utc());
            if (!response.data.ok) throw new Error('Error creando las cron tasks');
            Alert({ icon: 'success', text: response.data.ok });
            getSpeedDates();
            Alert({
                title: '¿Deseas predefinir usuarios para el cluster?',
                text: 'Aquí podras elegir usuarios para hacer tus pruebas',
                icon: 'sucess',
                showCancelButton: true,
                confirmButtonColor: '#e09633',
                cancelButtonColor: '#aaa',
                confirmButtonText: 'Si, Clusters ->',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    history.push(`${match.path}/${newLSDId}/users`);
                } else {
                    history.goBack();
                }
            });
        } catch (error) {
            console.log(error);
            Alert({ icon: 'error', title: 'Error', text: error.message });
        } finally {
            actions.setSubmitting(false);
        }
    }
    return (
        <AppContainer title="Creación de Live Speed Date">
            <SpeedDateForm onSubmit={onSubmit} />
        </AppContainer>
    )
}


export default function CreateSpeedDate() {
    const match = useRouteMatch();
    return (
        <UsersProvider>
            <Switch>
                <Route
                    path={`${match.path}/:id/users`}
                    component={LiveSpeedDateUsers}
                />
                <Route path={`${match.path}`} component={CreateSpeedDateBase} />
            </Switch>
        </UsersProvider>
    )
}

import React from 'react'
export default function MixedWidgetItem({ label1, classNameIcon, label2, color,className }) {
    return (
        <div className={`col bg-light-${color} px-6 py-8 rounded-xl mb-7 d-flex flex-column ${className}`}>
            <i className={`${classNameIcon || "fas fa-users"} text-${color}`} style={{fontSize:25}}></i>
            <a
                href="#"
                className={`text-${color} font-size-h6 mt-2`}
            >
                {label1}
            </a>
            <span className={`text-${color} font-weight-bold font-size-h2 mt-2`}>
                {label2}
            </span>
        </div>
    )
}


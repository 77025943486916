import * as firebase from "firebase/firebase";
import "firebase/auth";
//import dataConfig from './dataConfig'

const app = firebase.initializeApp(
    {
        apiKey: "AIzaSyD6GYVd05XhvVlRI9T_NZzavpNIl3w-a6M",
        authDomain: "cuploo.firebaseapp.com",
        databaseURL: "https://cuploo.firebaseio.com",
        projectId: "cuploo",
        storageBucket: "cuploo.appspot.com",
        messagingSenderId: "422751942150",
        appId: "1:422751942150:web:ebe11cf51f9db5cc7a7587",
        measurementId: "G-YZDDEB91R0"
    }
);

/*const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();*/

export default app;
import React, { useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import SessionStorageManager from '../../cuploo-src/utils/manageSessionStorage';
import { constructUrl } from '../utils'
const SpeedDatesContext = React.createContext();
SpeedDatesContext.displayName = 'SpeedDatesContext';
export function SpeedDatesProvider(props) {
    const [state, setState] = useState(SessionStorageManager.readSessionStorage(SpeedDatesContext.displayName, {
        speedDates: [],
        selectedSpeedDateId: [],
        wasSpeedDatesLoaded: false,
        hasCreationButton:true,
        resourceName:'Live Speed Date'
    }));
    const sessionStateMiddleware = (values) => {
        SessionStorageManager.writeSessionStorage(SpeedDatesContext.displayName, values);
        setState(values);
    }
    const getSpeedDates = async () => {
        let response = await Axios.get(constructUrl(`api/speed_dates`));
        let speedDates = [...response.data.items];
        sessionStateMiddleware({ ...state, speedDates, wasSpeedDatesLoaded: true });
    }
    useEffect(() => {
        getSpeedDates();
    }, [])

    const value = useMemo(() => {
        return ([
            state,
            sessionStateMiddleware,
            getSpeedDates,
        ])
    }, [state])

    return <SpeedDatesContext.Provider value={value} {...props} />
}

export function useSpeedDates() {
    const context = React.useContext(SpeedDatesContext);
    if (!context) {
        throw new Error('useSpeedDates debe estar dentro del proveedor SpeedDatesProvider');
    }
    return context;
}
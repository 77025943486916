import { Button, Dropdown, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import React from 'react'
import { useUsers } from "../../../context/UsersContext";
import AppTable from "../../../includes/partials/tables/AppTable";
import { AppContainer } from "../../Layout/AppContainer";
import { findById, getNaturalizedDate } from "../../../utils";
export const UserSpeedPacks = (props) => {
    const [usersState, setUsersState] = useUsers();
    let currentUser=findById(usersState.users,usersState.selectedUserId);
    let speed_packs = currentUser.speed_packs;
    
    if (!usersState.wasUsersLoaded) {
        return (<div className='d-flex justify-content-center h-100 align-items-center'>
            <Spinner animation="grow" />
        </div>
        )
    }
    let headersAndSelectors=[
        {header:'Paquete No.',selector:'id'},
        {header:'Cantidad Adquirida',selector:'speed_dates_quantity'},
        {header:'Cantidad Restante',selector:'remain_quantity'},
        {header:'Fecha de adquisición',selector:'acquired_at',skin:getNaturalizedDate},
    ];
   
    return (
        <AppContainer title={"SpeedPacks adquiridos por "+ currentUser.name} hasExportationButton={false}>
            <AppTable data={speed_packs} headersAndSelectors={headersAndSelectors} buttonsOfOptions={null}/>
        </AppContainer>)
}
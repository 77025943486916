import React, { useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import SessionStorageManager from '../../cuploo-src/utils/manageSessionStorage';
import { constructUrl } from '../utils'
const SpeedPacksContext = React.createContext();
SpeedPacksContext.displayName = 'SpeedPacksContext';
export function SpeedPacksProvider(props) {
    const [state, setState] = useState(SessionStorageManager.readSessionStorage(SpeedPacksContext.displayName, {
        speedPacks: [],
        selectedSpeedPackId: [],
        wasSpeedPacksLoaded: false,
        hasCreationButton:true,
        resourceName:'SpeedPack'
    }));
    const sessionStateMiddleware = (values) => {
        SessionStorageManager.writeSessionStorage(SpeedPacksContext.displayName, values);
        setState(values);
    }
    const getSpeedPacks = async () => {
        let response = await Axios.get(constructUrl(`api/speed_packs`));
        let speedPacks = [...response.data.items];
        sessionStateMiddleware({ ...state, speedPacks, wasSpeedPacksLoaded: true });
    }
    useEffect(() => {
        getSpeedPacks();
    }, [])

    const value = useMemo(() => {
        return ([
            state,
            sessionStateMiddleware,
            getSpeedPacks,
        ])
    }, [state])

    return <SpeedPacksContext.Provider value={value} {...props} />
}

export function useSpeedPacks() {
    const context = React.useContext(SpeedPacksContext);
    if (!context) {
        throw new Error('useSpeedPacks debe estar dentro del proveedor SpeedPacksProvider');
    }
    return context;
}
import React, { Component, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import { ExportDropdown } from "../../includes/partials/dropdowns";
import { useHistory, useRouteMatch } from "react-router-dom";
export const AppContainer = ({
  children,
  title,
  hasCreationButton,
  hasExportationButton,
  resourceName,
  dataExport,
  headerExport,
  tablePdf,
  csvData2Export,
  csvHeaders2Export
}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const goToCreate = () => {
    history.push(`${match.path}/nuevo`);
  };

  const pageChildrenRef = useRef(null);

  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        {hasCreationButton && (
          <Button
            className="btn btn-info btn-sm mr-2 px-5"
            onClick={goToCreate}
          >
            <i className="flaticon2-add mr-2"></i>
            Crear {resourceName}
          </Button>
        )}
        {hasExportationButton && (
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              className="btn btn-info btn-sm font-weight-bolder dropdown-toggle px-5"
              variant="transparent"
              id="dropdown-toggle-top"
            >
              Exportar
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <ExportDropdown
                dataExport={dataExport}
                headerExport={headerExport}
                csvData2Export={csvData2Export}
                csvHeaders2Export={csvHeaders2Export}
                title={title}
                pageRef={pageChildrenRef}
                tablePdf={tablePdf}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </div>
  );
  return (
    <Card className="example example-compact">
      <CardHeader title={title} toolbar={toolbar} />
      <CardBody>{children}</CardBody>
    </Card>
  );
};

import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { SpeedDatesProvider } from "../../context/SpeedDatesContext";
import CreateSpeedDate from "./CreateSpeedDate";
import UpdateSpeedDate from "./UpdateSpeedDate";
import { SpeedDateInfo } from "./speed_date_info";
import { SpeedDatesList } from "./SpeedDatesList";
import {LiveSpeedDateUsers } from "./includes/LiveSpeedDateUsers"
export default (props) => {
  let match = useRouteMatch();
  return (
    <SpeedDatesProvider>
      <Switch>
        <Route path={`${match.path}/editar`} component={UpdateSpeedDate} />
        <Route path={`${match.path}/nuevo`} component={CreateSpeedDate} />
        <Route
          path={`${match.path}/:id`}
          render={(props) => {
            let id_item = props.location.pathname.replace("/speed_dates/", "");
            return <SpeedDateInfo id_item={id_item} />;
          }}
        />
        <Route path={`${match.path}`} component={SpeedDatesList} />
      </Switch>
    </SpeedDatesProvider>
  );
};

import { Button, Dropdown, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import React from 'react'
import AppTable from "../../includes/partials/tables/AppTable";
import { constructUrl } from "../../utils";
import { AppContainer } from "../Layout/AppContainer";
import { useHistory, useRouteMatch } from "react-router-dom";
import Alert from "../../includes/Alert";
import Axios from "axios";
import { getNaturalizedDate } from "../../utils";
import { useMainQuestions } from "../../context/MainQuestionsContext";
export const MainQuestionsList = (props) => {
    const [mainQuestionsState, setMainQuestionsState, getMainQuestions] = useMainQuestions();
    const history = useHistory();
    const match = useRouteMatch();
    let mainQuestions = mainQuestionsState.mainQuestions;
    if (!mainQuestionsState.wasMainQuestionsLoaded) {
        return (<div className='d-flex justify-content-center h-100 align-items-center'>
            <Spinner animation="grow" />
        </div>
        )
    }
    let headersAndSelectors = [
        { header: 'URL del video', selector: 'video_url'},
    ]

    const openEditForm = (id) => {
        setMainQuestionsState({ ...mainQuestionsState, selectedMainQuestionId: id });
        history.push(`${match.path}/editar`);
    }
    const deleteItem = async (id) => {
        try {
            let response = await Axios.get(constructUrl(`api/main_questions/${id}/delete`));
            if (!response.data.ok) throw new Error('Error al eliminar');
            Alert({ icon: 'success', text: response.data.ok });
            getMainQuestions();
        } catch (error) {
            Alert({ icon: 'error', title: 'Error', text: error });
        }
    }
    const buttonsOfOptions = (id) => {
        const buttons = [];
        buttons.push(
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="latest-project">Editar</Tooltip>
                }
            >
                <Button className="btn btn-primary btn-sm" onClick={() => {
                    openEditForm(id);
                }}>
                    {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                    <i className='flaticon2-pen' />
                </Button>
            </OverlayTrigger>
        )
        buttons.push(
            <OverlayTrigger

                placement="top"
                overlay={
                    <Tooltip id="latest-project">Eliminar</Tooltip>
                }
            >
                <Button className="btn btn-danger btn-sm ml-2" onClick={
                    () => {
                        Alert({
                            title: '¿Estás seguro?',
                            text: '¿Deseas eliminar esta pregunta?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#D0002E',
                            cancelButtonColor: '#aaa',
                            confirmButtonText: 'Si, elimínalo',
                            cancelButtonText: 'Cancelar'
                        }).then((result) => {
                            if (result.value) {
                                deleteItem(id);
                            }
                        });
                    }
                }>
                    {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
                    <i className='flaticon2-delete' />
                </Button>
            </OverlayTrigger>
        )
        return buttons
    }
    return (
        <AppContainer
            title="Preguntas principales"
            hasCreationButton={mainQuestionsState.hasCreationButton}
            resourceName={mainQuestionsState.resourceName}
            hasExportationButton={true}
        >
            <AppTable data={mainQuestions} headersAndSelectors={headersAndSelectors} buttonsOfOptions={buttonsOfOptions} />
        </AppContainer>)
}
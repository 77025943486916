import * as yup from 'yup';
import AppConstants from './AppConstants';
import currencyFormatter from 'currency-formatter';
import moment from 'moment';
export { default as AppConstants } from './AppConstants';
moment.locale('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sábado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
});
export const constructUrl = (baseUrl, query) => {
    let url = AppConstants.API_URL + baseUrl;
    if (query) {
        url += "?";
        let keys = Object.keys(query);
        let parts = keys.map(key =>
            `${key}=${JSON.stringify(query[key])}`
        )
        url += parts.join('&')
    }
    return url;
}

export const getValueBySelector = (row, headerAndSelector) => {
    //A veces los selectores vienen como hijo.nieto
    //entonces hay que encontrar ese valor para el nieto
    const { selector, skin, params, where } = headerAndSelector;
    if (selector.includes(".")) {
        let result = null;
        let SelectorParts = selector.split(".");
        SelectorParts.forEach(selectorPart => {
            result = result ? result[selectorPart] : row[selectorPart];
        });
        return result
    }
    //de lo contrario haga una selección normal
    if (Array.isArray(row[selector])) {
        let whereResultArray = row[selector].filter(item => {
            let flag = false;
            if (!where) return true;
            let wheresKey = Object.keys(where);
            for (let key of wheresKey) {
                if (item[key] == where[key] || (!where[key] && !item[key])) {
                    flag = true;
                    break;
                }
            }
            return flag;
        });
        return whereResultArray.map(item => {
            if (selector === 'interests') {
                item = item.hashtag;
            }
            if (skin) {
                if (params) {
                    return skin(item, ...params);
                }
                return skin(item);
            }
            return item;
        }).join(',');

    }
    if (skin) {
        if (params) {
            return skin(row[selector], ...params);
        }
        return skin(row[selector]);
    }
    return row[selector];
}

export const currencyFormat = (value, code = "COP") => {
    return currencyFormatter.format(value, { code, precision: 0 });
}


export const getDifferentValues = (obj1, obj2) => {
    let differentValues = {};
    const keys = Object.keys(obj1)
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (obj1[key] !== obj2[key]) {
            differentValues[key] = obj2[key];
        }
    }
    return differentValues;
}

export function createYupSchema(schema, config) {
    const { id, validationType, validations = [] } = config;
    if (!yup[validationType]) {
        return schema;
    }
    let validator = yup[validationType]();
    validations.forEach(validation => {
        const { params, type } = validation;
        if (!validator[type]) {
            return;
        }
        validator = validator[type](...params);
    });
    schema[id] = validator;
    return schema;
}


export const findById = (array, id) => {
    return array.find(item => item.id === parseInt(id));
}

export const getNaturalizedDate = (dateSource, separator = '', todayLabel = true, withHour = false) => {
    let date = moment(dateSource).utc();
    let label = date.isSame(moment(), 'day') && todayLabel ? 'Hoy' : date.format('DD {} MMMM {} YYYY').split("{}").join(separator);
    if (!withHour) {
        return label;
    }
    return label + " hora : " + date.format('hh:mm A');
}

export const hashtag = (s) => {
    return `#${s}`;
}
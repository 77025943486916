import React from 'react'
import { AppContainer } from '../Layout/AppContainer'
import SpeedPackForm from './forms/SpeedPackForm'
import { useSpeedPacks } from "../../context/SpeedPacksContext";
import { constructUrl, findById } from '../../utils';
import Alert from '../../includes/Alert';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
export default function UpdateSpeedPack() {
    const [speedPacksState,setSpeedPacksState,getSpeedPacks]=useSpeedPacks();
    const history = useHistory();
    const currentSpeedPack=findById(speedPacksState.speedPacks,speedPacksState.selectedSpeedPackId);
    const onSubmit = async (values, actions) => {
        try {
            let response = await Axios.post(constructUrl(`api/speed_packs/${currentSpeedPack.id}/edit`), values);
            if (!response.data.ok) throw new Error('Error al actualizar');
            Alert({ icon: 'success', text: response.data.ok });
            getSpeedPacks();
            history.goBack();
        } catch (error) {
            Alert({ icon: 'error', title: 'Error', text: error });
        } finally {
            actions.setSubmitting(false);
        }
    }
    return (
        <AppContainer title="Actualización Speed Pack">
            <SpeedPackForm onSubmit={onSubmit} speedPack={currentSpeedPack}/>
        </AppContainer>
    )
}


import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { UserReportsProvider } from '../../context/UserReportsContext';
import { UserReportsList } from './UserReportsList';
// import CreateSpeedPack from './CreateSpeedPack';
// import UpdateSpeedPack from './UpdateSpeedPack';

export default (props) => {
    let match = useRouteMatch();
    return (
        <UserReportsProvider>
            <Switch>
                {/* <Route path={`${match.path}/editar`} component={UpdateSpeedPack} />
                <Route path={`${match.path}/nuevo`} component={CreateSpeedPack} /> */}
                <Route path={`${match.path}`} component={UserReportsList} />
            </Switch>
        </UserReportsProvider>
    );
}


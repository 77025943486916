import React from 'react'
import { AppContainer } from '../Layout/AppContainer'
import MainQuestionForm from './forms/MainQuestionForm'
import { constructUrl } from '../../utils';
import Alert from '../../includes/Alert';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useMainQuestions } from '../../context/MainQuestionsContext';
export default function CreateMainQuestion() {
    const history = useHistory();
    const [getMainQuestions] = useMainQuestions().slice(-1);
    const onSubmit = async (values, actions) => {
        try {
            let response = await Axios.post(constructUrl('api/main_questions'), values);
            if (!response.data.ok) throw new Error('Error al crear');
            Alert({ icon: 'success', text: response.data.ok });
            getMainQuestions();
            history.goBack();
        } catch (error) {
            Alert({ icon: 'error', title: 'Error', text: error });
        } finally {
            actions.setSubmitting(false);
        }
    }
    return (
        <AppContainer title="Creación de Pregunta Pincipal">
            <MainQuestionForm onSubmit={onSubmit} />
        </AppContainer>
    )
}


import {
  Button,
  Dropdown,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import React, { useRef} from "react";
import AppTable from "../../includes/partials/tables/AppTable";
import { constructUrl, currencyFormat } from "../../utils";
import { AppContainer } from "../Layout/AppContainer";
import { useHistory, useRouteMatch } from "react-router-dom";
import Alert from "../../includes/Alert";
import Axios from "axios";
import { useSpeedDates } from "../../context/SpeedDatesContext";
import { getNaturalizedDate } from "../../utils";
export const SpeedDatesList = (props) => {
  const [speedDatesState, setSpeedDatesState, getSpeedDates] = useSpeedDates();
  const history = useHistory();
  const match = useRouteMatch();
  const tablePdf = useRef(null);


  let speedDates = speedDatesState.speedDates;
  if (!speedDatesState.wasSpeedDatesLoaded) {
    return (
      <div className="d-flex justify-content-center h-100 align-items-center">
        <Spinner animation="grow" />
      </div>
    );
  }
  let headersAndSelectors = [
    {
      header: "Fecha",
      selector: "date",
      skin: getNaturalizedDate,
      params: ["de", false, true],
    },
  ];

  const openEditForm = (id) => {
    setSpeedDatesState({ ...speedDatesState, selectedSpeedDateId: id });
    history.push(`${match.path}/editar`);
  };
  const deleteItem = async (id) => {
    try {
      let response = await Axios.get(
        constructUrl(`api/speed_dates/${id}/delete`)
      );
      if (!response.data.ok) throw new Error("Error al eliminar");
      Alert({ icon: "success", text: response.data.ok });
      getSpeedDates();
    } catch (error) {
      Alert({ icon: "error", title: "Error", text: error });
    }
  };

  const showInfoItem = (id) => {
    history.push(`${match.path}/${id}`);
  };

  const buttonsOfOptions = (item) => {
    const { id } = item;
    const buttons = [];
    buttons.push(
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="latest-project">Ver</Tooltip>}
      >
        <Button
          className="btn btn-success btn-sm"
          onClick={() => {
            showInfoItem(id);
          }}
        >
          {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
          <i className="fas fa-info-circle" />
        </Button>
      </OverlayTrigger>
    );
    buttons.push(
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="latest-project">Editar</Tooltip>}
      >
        <Button
          className="btn btn-primary btn-sm ml-2"
          onClick={() => {
            openEditForm(id);
          }}
        >
          {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
          <i className="flaticon2-pen" />
        </Button>
      </OverlayTrigger>
    );
    buttons.push(
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="latest-project">Eliminar</Tooltip>}
      >
        <Button
          className="btn btn-danger btn-sm ml-2"
          onClick={() => {
            Alert({
              title: "¿Estás seguro?",
              text: "¿Deseas eliminar esta Speed Pack?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#D0002E",
              cancelButtonColor: "#aaa",
              confirmButtonText: "Si, elimínalo",
              cancelButtonText: "Cancelar",
            }).then((result) => {
              if (result.value) {
                deleteItem(id);
              }
            });
          }}
        >
          {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
          <i className="flaticon2-delete" />
        </Button>
      </OverlayTrigger>
    );
    return buttons;
  };
  return (
    <AppContainer
      title="Live Speed Dates"
      hasCreationButton={speedDatesState.hasCreationButton}
      resourceName={speedDatesState.resourceName}
      hasExportationButton={true}
      dataExport={speedDates}
      headerExport={headersAndSelectors}
      tablePdf={tablePdf}
    >
      <AppTable
        data={speedDates}
        headersAndSelectors={headersAndSelectors}
        buttonsOfOptions={buttonsOfOptions}
        item
        tablePdf={tablePdf}
      />
    </AppContainer>
  );
};

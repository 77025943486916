import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import CreateMainQuestion from './CreateMainQuestions';
import UpdateMainQuestion from './UpdateMainQuestion';
import {MainQuestionsList} from './MainQuestionsList';
import { MainQuestionsProvider } from '../../context/MainQuestionsContext';
export default (props) => {
    let match = useRouteMatch();
    return (
        <MainQuestionsProvider>
            <Switch>
                <Route path={`${match.path}/editar`} component={UpdateMainQuestion} />
                <Route path={`${match.path}/nuevo`} component={CreateMainQuestion} />
                <Route path={`${match.path}`} component={MainQuestionsList} />
            </Switch>
        </MainQuestionsProvider>
    );
}


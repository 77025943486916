import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../../_helpers';

export function AsideItem({ title, tabId, activeTab, classNameIcon, handleTabChange,onClick }) {
    return (
        <li
            className="nav-item mb-3"
            data-toggle="tooltip"
            data-placement="rigth"
            data-container="body"
            data-boundary="window"
            title={title}
        >
            <OverlayTrigger
                placement="right"
                overlay={
                    <Tooltip id="latest-project">{title}</Tooltip>
                }
            >
                <a
                    href="#"
                    className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                        tabId && "active"}`}
                    data-toggle="tab"
                    data-target={`#${tabId}`}
                    role="tab"
                    onClick={() => {
                        onClick && onClick()
                        handleTabChange(tabId)
                    }}
                >
                    {/* <span className="svg-icon svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Layout/Layout-4-blocks.svg"
                )}
              />
            </span> */}
                    <i className={classNameIcon || `fas fa-users`}></i>
                </a>
            </OverlayTrigger>
        </li>
    )
}
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function Alert(options) {

    const MySwal = withReactContent(Swal);
    return MySwal.fire({
        ...options, 
        confirmButtonColor: '#19bcdb',
        cancelButtonColor: '#aaa'
    });

}

export default Alert;
import React from 'react';
import AppForm from '../../../includes/partials/forms/AppForm';

export default function SpeedDateForm({ speedDate, onSubmit }) {
    let formData = [
        {
            id: "date",
            label: "Fecha",
            placeholder: "Ingrese la Fecha",
            type: "datetime-local",
            validationType: "date",
            value: speedDate ? speedDate["date"] : "",
            validations: [
                {
                    type: "required",
                    params: ["La Fecha es requerida"]
                },
            ]
        }
    ];

    return (
        <>
            <AppForm items={formData} onSubmit={onSubmit} isEditForm={speedDate != null} submitLabel={speedDate ? 'Actualizar' : "Crear"} />
        </>
    )
}


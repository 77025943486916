import React from 'react';
import AppForm from '../../../includes/partials/forms/AppForm';

export default function SpeedPackForm({ speedPack, onSubmit }) {
    let formData = [
        {
            id: "speed_dates_quantity",
            label: "Cantidad de Live Speed Dates",
            placeholder: "Ingrese la cantidad",
            type: "number",
            validationType: "number",
            value: speedPack ? speedPack["speed_dates_quantity"] : "",
            validations: [
                {
                    type: "required",
                    params: ["La cantidad es requerida"]
                },
            ]
        },
        {
            id: "price",
            label: "Precio Speed Pack",
            placeholder: "Ingrese el precio",
            type: "number",
            validationType: "number",
            value: speedPack ? speedPack["price"] : "",
            validations: [
                {
                    type: "required",
                    params: ["Este campo es requerido"]
                }
            ]
        }
    ];

    return (
        <>
            <AppForm items={formData} onSubmit={onSubmit} isEditForm={speedPack != null} submitLabel={speedPack ? 'Actualizar' : "Crear"} />
        </>
    )
}


import {
  Button,
  Dropdown,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import React, { useState, useRef, useEffect } from "react";
import AppTable from "../../includes/partials/tables/AppTable";
import { constructUrl, currencyFormat, getNaturalizedDate } from "../../utils";
import { AppContainer } from "../Layout/AppContainer";
import { useHistory, useRouteMatch } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Alert from "../../includes/Alert";
import Axios from "axios";
import { useUserReports } from "../../context/UserReportsContext";
export const UserReportsList = (props) => {
  // const [userReportsState, setUserReportsState,] = useUserReports();
  // const history = useHistory();
  // const match = useRouteMatch();
  const [showScreenshot, setShowScreenShot] = useState(false);
  const [userReports, setUserReports] = useState([
    {
      date: "23/20/20",
      reporter_user_name: "pedro",
      reporter_user_pin: "2039923",
      reported_user_name: "juan",
      reported_user_pin: "912321",
      report_reason: "acoso",
      pictures: [
        "https://ichef.bbci.co.uk/news/624/amz/worldservice/live/assets/images/2015/06/12/150612173542_mascota_promo_624x351_thinkstock.jpg",
        "",
        "https://ichef.bbci.co.uk/news/624/amz/worldservice/live/assets/images/2015/06/12/150612173542_mascota_promo_624x351_thinkstock.jpg",
      ],
    },
    {
      date: "23/20/20",
      reporter_user_name: "pedro",
      reporter_user_pin: "2039923",
      reported_user_name: "juan",
      reported_user_pin: "912321",
      report_reason: "acoso",
      pictures: [
        "",
        "https://ichef.bbci.co.uk/news/624/amz/worldservice/live/assets/images/2015/06/12/150612173542_mascota_promo_624x351_thinkstock.jpg",
        "",
      ],
    },
  ]);
  const [loading, setLoading] = useState(false);
  const tablePdf = useRef(null);
  const getUserReports = async () => {
    setLoading(true);
    let response = await Axios.get(constructUrl(`api/user_reports`));
    let reports = [...response.data];

    setUserReports(reports);

    setLoading(false);
  };
  useEffect(() => {
    getUserReports();
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center h-100 align-items-center">
        <Spinner animation="grow" />
      </div>
    );
  }
  let headersAndSelectors = [
    {
      header: "Date",
      selector: "date",
      skin: getNaturalizedDate,
      params: ["de", false, true],
    },
    { header: "CC Reportador", selector: "reporter_user_pin" },
    {
      header: "Nombre Reportador",
      selector: "reporter_user_name",
    },
    {
      header: "CC Usuario Reportado",
      selector: "reported_user_pin",
    },
    {
      header: "Nombre Usuario Reportado",
      selector: "reported_user_name",
    },
    { header: "Razón de reporte", selector: "report_reason" },
  ];

  // const openEditForm = (id) => {
  //     setUserReportsState({ ...userReportsState, selectedSpeedPackId: id });
  //     history.push(`${match.path}/editar`);
  // }
  // const deleteItem = async (id)=>{
  //     try {
  //         let response = await Axios.get(constructUrl(`api/speed_packs/${id}/delete`));
  //         if (!response.data.ok) throw new Error('Error al eliminar');
  //         Alert({ icon: 'success', text: response.data.ok });
  //         getUserReports();
  //     } catch (error) {
  //         Alert({ icon: 'error', title: 'Error', text: error });
  //     }
  // }

  const buttonsOfOptions = (object) => {
    if (object.pictures && object.pictures.length > 0) {
      const buttons = [];
      buttons.push(
        <OverlayTrigger
          key={"screenshots_button"}
          placement="top"
          overlay={<Tooltip id="latest-project">ScreenShots</Tooltip>}
        >
          <Button
            className="btn btn-primary btn-sm"
            onClick={() => {
              setShowScreenShot(object.pictures);
            }}
          >
            {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
            <i className="flaticon2-photograph" />
          </Button>
        </OverlayTrigger>
      );

      return buttons;
    }
  };
  return (
    <AppContainer
      title="Reportes de usuarios"
      hasCreationButton={false}
      resourceName="Reporte de usuario"
      hasExportationButton={true}
      dataExport={userReports}
      headerExport={headersAndSelectors}
      tablePdf={tablePdf}
    >
      <AppTable
        data={userReports}
        headersAndSelectors={headersAndSelectors}
        buttonsOfOptions={buttonsOfOptions}
        search
        item
        tablePdf={tablePdf}
      />

      {showScreenshot && (
        <div
          style={{
            position: "absolute",

            width: "100%",
            top: 0,
            left: 0,
            background: "rgba(0,0,0, 0.1)",
          }}
        >
          <Carousel style={{backgroundColor: '#00000055', borderRadius:20}}>
            {showScreenshot.map((pinture, index) => {
              const position = ["First", "Second", "Third"];
              return (
                <Carousel.Item style={{borderRadius:20}} key={pinture + index}>
                  <img
                    style={{ height: "85vh" }}
                    className="d-block mx-auto"
                    src={
                      pinture
                        ? pinture
                        : "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_178756def82%20text%20%7B%20fill%3A%23ffffff%3Bfont-weight%3Anormal%3Bfont-family%3A-apple-system%2CBlinkMacSystemFont%2C%26quot%3BSegoe%20UI%26quot%3B%2CRoboto%2C%26quot%3BHelvetica%20Neue%26quot%3B%2CArial%2C%26quot%3BNoto%20Sans%26quot%3B%2C%26quot%3BLiberation%20Sans%26quot%3B%2Csans-serif%2C%26quot%3BApple%20Color%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Emoji%26quot%3B%2C%26quot%3BSegoe%20UI%20Symbol%26quot%3B%2C%26quot%3BNoto%20Color%20Emoji%26quot%3B%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_178756def82%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22292.45833587646484%22%20y%3D%22219.05%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                    }
                    alt={`${position[index]} slide`}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>

          <Button
            onClick={() => setShowScreenShot(false)}
            style={{
              borderRadius:1000,
              color: "#f64e60",
              fontSize: 20,
              position: "absolute",
              right: -20,
              top: -20,
            }}
          >
            {" "}
            &#10005;{" "}
          </Button>
        </div>
      )}
    </AppContainer>
  );
};

import React, { useState, useEffect, useMemo } from "react";
import Axios from "axios";
import SessionStorageManager from "../../cuploo-src/utils/manageSessionStorage";
import { constructUrl } from "../utils";
const UserReportsContext = React.createContext();
UserReportsContext.displayName = "UserReportsContext";
export function UserReportsProvider(props) {
  const [state, setState] = useState(
    SessionStorageManager.readSessionStorage(UserReportsContext.displayName, {
      userReports: [{name: "hola"},{name: "como estas"}],
      selectedUserId: [],
      wasUserReportsLoaded: false,
      hasCreationButton: false,
      resourceName: "Reporte de usuario",
    })
  );
  const sessionStateMiddleware = (values) => {
    SessionStorageManager.writeSessionStorage(
      UserReportsContext.displayName,
      values
    );
    setState(values);
  };
  //get users report
  const getUserReports = async () => {
    const query = {
      graphs: ["reporter_user", "reported_user", "reportReason"],
    };
    let response = await Axios.get(constructUrl(`api/user_reports`));

    let usersReport = [...response.data.items];

    sessionStateMiddleware({
      ...state,
      usersReport,
      wasUserReportsLoaded: true,
    });
  };
//   useEffect(() => {
//     getUserReports();
//   }, []);

  const value = useMemo(() => {
    return [state, sessionStateMiddleware, getUserReports];
  }, [state]);

  return <UserReportsContext.Provider value={value} {...props} />;
}

export function useUserReports() {
  const context = React.useContext(UserReportsContext);
  if (!context) {
    throw new Error(
      "useUserReports debe estar dentro del proveedor UserReportsProvider"
    );
  }
  return context;
}
